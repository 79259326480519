import NavigationBar from "../../components/NavigationBar";

export default function Photography() {
    return (
        <div>
            <NavigationBar defaultValue="photography"/> 
            <h1>Photography</h1>
            <p>This is the photography page</p>
        </div>
    );
}